export default [
  {
    header: ' ',
  },
  {
    title: 'Order',
    route: 'order-home',
    icon: 'ClipboardIcon',
  },
  {
    title: 'Order List',
    route: 'order-list',
    icon: 'FileTextIcon',
  },
  {
    title: 'Customer',
    route: 'customer',
    icon: 'UserIcon',
  },
  {
    title: 'Events',
    route: 'events',
    icon: 'CalendarIcon',
  },
  {
    title: 'Task',
    route: 'task',
    icon: 'ListIcon',
  },
  {
    title: 'Manage',
    route: 'manage-menu',
    icon: 'SettingIcon',
  },
]
